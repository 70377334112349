import React from 'react'
import "./prices.css"
import { Link } from 'react-router-dom'

const prices = () => {
  return (
    <div>
    <div className=' container-fee'>
        <div className=' MAIN-CONTANIER'>
            <div className=' text'> 
               <span>Join The Family</span>       
            <h1>BE COME A <br></br>MEMBER </h1>
            </div>
           
        </div>
          <div className="fee_card">
           <div className='heanding'>
          <h2>Monthly Plane per Student</h2>
          </div>
              <div className="men-fees-box">
                
                <div className="card_1">
                    <h1 className='basic'>
                       <h3>BASIC</h3>  
                    </h1>
                    <h2>$50 /mon </h2>
                    <ul>
                        <li> One-on-One Sessions</li>
                        <hr />
                        <li> 5 days class per week</li>
                        <hr />
                        <li> 45 minutes class per day</li>
                        <hr />
                        <li> Learning Material</li>
                        <hr />
                        <li> 24/7 Support by WhatsApp</li>
                        <hr />
                       <li> Sessions recordings</li>
                       <hr />
                    </ul>
                    <Link className="Form1" to="/Form1">
                    <div className="addToREgister1">
                      Rigster       
                    </div>
                    </Link> 
                </div>
                <div className="card_1">
                <h1 className='basic'>
                       <h3>PREMIUM</h3>  
                    </h1>
                    <h2>$65 /mon </h2>
                    <ul>
                        <li> One-on-One Sessions</li>
                        <hr />
                        <li> 5 days class per week</li>
                        <hr />
                        <li> 45 minutes class per day</li>
                        <hr />
                        <li> Learning Material</li>
                        <hr />
                        <li> 24/7 Support by WhatsApp</li>
                        <hr />
                       <li> Sessions recordings</li>
                       <hr />
                    </ul>
                    <Link className="Form1" to="/Form1">
                    <div className="addToREgister2">
                      Rigster       
                    </div>
                    </Link> 
                </div>
                <div className="card_1">
                <h1 className='basic'>
                       <h3>ADVANCED</h3>  
                    </h1>
                    <h2>$75 /mon </h2>
                    <ul>
                        <li> One-on-One Sessions</li>
                        <hr />
                        <li> 5 days class per week</li>
                        <hr />
                        <li> 45 minutes class per day</li>
                        <hr />
                        <li> Learning Material</li>
                        <hr />
                        <li> 24/7 Support by WhatsApp</li>
                        <hr />
                       <li> Sessions recordings</li>
                       <hr />
                    </ul>
                    <Link className="Form1" to="/Form1">
                    <div className="addToREgister">
                      Rigster   
                    </div>
                    </Link>
                </div>
              </div>
        </div>
        
    </div>
    </div>
  )
}

export default prices
