import React from 'react'
import Slider from "react-slick";
import './Logoslider.css'
import logofoter from "../../img/logofoter.jpg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Logoslider() {
    const settings = {
        // dots: true,
        // infinite: true,
        slidesToShow: 1,
        // slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        cssEase: "linear"

    };
    return (
        <div className='main-container'>
            <span className='text-lo'>Books</span>
            <div className="logocontainer">
                <Slider {...settings}>
                    {/* <div className='contanier'>
                        <h3>1</h3>
                    </div> */}
                    <div className="book-box">
                        <img src="https://hamariweb.com/islam/hadith/1.jpg" alt="" />
                        <span>Sahih Bukhari</span>
                    </div>
                    <div className="book-box">
                        <img src="https://hamariweb.com/islam/hadith/2.jpg" alt="" />
                        <span>Sahih Muslim</span>
                    </div>
                    <div className="book-box">
                        <img src="https://hamariweb.com/islam/hadith/3.jpg" alt="" />
                        <span>jami at Tirmidhi</span>
                    </div>
                    <div className="book-box">
                        <img src="https://hamariweb.com/islam/hadith/4.jpg" alt="" />
                        <span>sunan Abu Dawood</span>
                    </div>
                    <div className="book-box">
                        <img src="https://hamariweb.com/islam/hadith/5.jpg" alt="" />
                        <span>Sunan An Nasia</span>
                    </div>
                    <div className="book-box">
                        <img src="	https://hamariweb.com/islam/hadith/6.jpg" alt="" />
                        <span>Sunan Ibn Majah</span>
                    </div>

                </Slider>

                <div className='container-23logo'>
                </div>
            </div>

        </div>
    )
}

export default Logoslider