import React, { useState } from 'react'
import './Login.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import logo from '../../img/logo.png'
import gmail from '../../img/download.png'
import whaticon from '../../img/download (4).png'
import ReactWhatsapp from 'react-whatsapp';
import { faYoutube, faWhatsapp, faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { F } from 'react-html5video/dist';
import { Helmet } from 'react-helmet-async';


const Login = () => {
    const [popupStyle, showPopup] = useState("hide")
    const popup = () => {
        showPopup("login-popup")
        setTimeout(() => showPopup("hide"), 3000)
    }
    return (
        <>        
        <Helmet>
         <title>Contact</title>
         <meta name="description" content=" Quran Academy offers online Quran classes and Islamic education for students of all ages. Learn Quran online with qualified teachers. " data-rh="ture" />
         <meta name="keywords" content="Quran Academy, Learn Quran Online, Islamic Education, Online Quran Classes, Quran Learning, Quran Teachers"/>
         <meta name="robots" content="index, follow"/>
         <link rel="Cononical" href="/Contact" />
        </Helmet>
        <div className="page">
            <div className="cover">
                <h1 style={{ color: 'white', }}> Contact US </h1>
                {/* <input type="text" placeholder='username' />
                <input type="password" placeholder='Password' />
                <div className="login-btn" onClick={popup}>Login</div>
                <p className='text' >or Login   using</p>
                <div className="alt-login">
                    <div className="facebooks"></div>
                    <div className="Google"></div>
                </div>
                // <div className={popupStyle}>
                //     <h3>Login Failed</h3>
                //     <p>Username or password incorrect</p>
                // </div> */}
                {/* <div className='contact'> */}
                <div className="number">
                    <div style={{}} className="text3">
                        <img src={whaticon} alt="" />
                        <ReactWhatsapp number="+92 3331115789" className='btn-2 btn-primary'> +92 333 111 5789 </ReactWhatsapp>
                    </div>
                </div>
                <div className="Email">
                    <img src={gmail} alt="" />
                    <a href="Info@qeducationonline.com">Info@qeducationonline.com</a>
                </div>
                <div className="facebook">
                    <a href="https://www.facebook.com/qeducationonline?mibextid=ZbWKwL">
                        <FontAwesomeIcon style={{ height: "40px", margin: "", color: "blue", }} icon={faFacebook}></FontAwesomeIcon>
                        <a href="https://www.facebook.com/qeducationonline?mibextid=ZbWKwL">qeducationonline.com</a>
                    </a>

                </div>

                <div className="intsagarm">
                    <div style={{ display: "flex" }} className="facebook">
                        <a href="https://instagram.com/qeducationonline?igshid=MzNlNGNkZWQ4Mg==">
                            <FontAwesomeIcon style={{ height: "40px", margin: "", color: "red" }} icon={faInstagram}></FontAwesomeIcon>
                            <a href="https://instagram.com/qeducationonline?igshid=MzNlNGNkZWQ4Mg==">qeducationonline.com</a>
                        </a>
                    </div>

                </div>
                <div className="twitter-c">
                    <div style={{ display: "flex" }} className="tiwtter">
                        <a href="https://twitter.com/qeducationlive?t=wIvqZX3nPP175rst7DqtAw&s=09">
                            <FontAwesomeIcon style={{ height: "40px", margin: "", color: "#00ACEE" }} icon={faTwitter}></FontAwesomeIcon>
                            <a href="https://twitter.com/qeducationlive?t=wIvqZX3nPP175rst7DqtAw&s=09">qeducationonline.com</a>
                        </a>
                    </div>

                </div>

                {/* </div> */}
                {/* <div className={popupStyle}>
                    <h3>Login Failed</h3>
                    <p>Username or password incorrect</p>
                </div> */}
            </div>

        </div >
        </>

    )
}

export default Login