
import React, { useState } from 'react';
import './NavBar.css'
// import './App.css'
import { Link } from 'react-router-dom'
import { FaBars, FaTimes } from "react-icons/fa";
import logo from '../img/logo.png'
const NavBar = () => {
    const [isMobile, SetIsMoblie] = useState(false);
    return (
        <>

            <nav className='navbar'>
                <h3 className='logo'><img style={{ height: '105px' }} src={logo} alt="" /></h3>
                <ul className={isMobile ? "nav-links-moblie" : "nav-links"}
                    onClick={
                        () => SetIsMoblie(false
                        )
                    }
                >

                    <Link className="home" to="/">
                        <li> Home </li>
                    </Link>
                    <Link className="Courses" to="/Courses">
                        <li> Courses </li>
                    </Link>
                    <Link className="Books" to="/Prices">
                        <li>Pricing</li>
                    </Link>

                    <Link className="Contact" to="/Contact"> <li> Contact </li></Link>
                    <Link className="Singup" to="/Contact">
                        <li> Sign UP </li>
                    </Link>

                </ul >
                <button className='moblie-menu-icon'
                    onClick={() => SetIsMoblie(!isMobile)}>

                    {isMobile ? (

                        <FaTimes />
                    ) : (
                        <FaBars />
                    )}
                </button>
            </nav >
        </>
    )
}

export default NavBar