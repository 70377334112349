import logo from './logo.svg';
import './App.css';
import NavBar from './componet/NavBar';
import Contant from './componet/contant/Contant';
import Prices from './componet/fees/prices';
import Logoslider from './componet/Firstlogoslider/Logoslider';
import Register from './componet/Registerform/Register';


import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import { NAvGpt } from './componet/ChatGPT/NavGPT/NAvGpt';
import { Min1box } from './componet/ChatGPT/1minbox/Min1box';
import Login from './componet/contact/Login';
import Form1 from './componet/formpage/Form1';
import {HelmetProvider} from 'react-helmet-async' ;
function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
       <HelmetProvider>      
         <NavBar />

        <Routes>
        <Route path="/Form1" element={<Form1/>} exact />
          <Route path="/" element={<Contant />} />
          <Route path="/Prices" element={<Prices />} exact />
          <Route path="/contact" element={<Login />} exact />  
          <Route path="/Courses" element={<Min1box />} exact />
        </Routes>
        <Register/>
        
        <NAvGpt />
        </HelmetProvider>
 
      </BrowserRouter>
    
      
    </div>
  );
}

export default App;
