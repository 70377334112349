import React from 'react'
import videothum from '../../img/videothum.jpg'
import './Videopass.css'
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import Quran from '../../img/Quran.mp4'
import { Link } from 'react-router-dom';


const Videopass = () => {
  return (
    <div className="Videopass">
      <div className="VideoBox">
        <Video style={{ height: "470px ", margin: "0%", width: "100%" }} autoPlay loop poster >

          <source className='videopass-img' src={Quran} autoPlay type='video/webm' />
        </Video>
      </div>
      {/* <Link className="Contact" to="/Contact">
        <button className="v-singup">Sign Up for free</button>
      </Link> */}
    </div >
  )
}

export default Videopass