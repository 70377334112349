import React from 'react'
import "./form.css"
const Form1 = () => {
  return (
    <div className='Register-form1'>
      <div className="btn-Register-form1"> 
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSecAdgYkRTkWD6dcWLr9GHrzUprHeWFjwkJ22aPk9dn1Cdcxw/viewform?embedded=true" width="100%" height="1400px" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>    
      </div>
    </div>
  )
}

export default Form1
