import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../../img/logo.png'
import gmail from '../../../img/download.png'
import whaticon from '../../../img/download (4).png'
import ReactWhatsapp from 'react-whatsapp';
import { faYoutube, faWhatsapp, faFacebook, faInstagram, } from "@fortawesome/free-brands-svg-icons";
import './NavGpt.css'
import { Helmet } from 'react-helmet-async';
export const NAvGpt = () => {
    return (
        <>
        <Helmet>
        <title>Quran Acadmey</title>
         <meta name="description" content=" Quran Academy offers online Quran classes and Islamic education for students of all ages. Learn Quran online with qualified teachers. " data-rh="ture" />
         <meta name="keywords" content="Quran Academy, Learn Quran Online, Islamic Education, Online Quran Classes, Quran Learning, Quran Teachers"/>
         <meta name="robots" content="index, follow"/>
         <link rel="Cononical" href="/footer" /> 
        </Helmet> 
        <div className="footer">
            <div className="foter-container">
                <h3 className='logo-f'><img src={logo} alt="" /></h3>
                <div className="iconlink">
                    <a href="https://www.facebook.com/qeducationonline?mibextid=ZbWKwL">
                        <FontAwesomeIcon style={{ height: "40px", margin: "20px", color: "blue" }} icon={faFacebook}></FontAwesomeIcon>

                    </a>
                    <a href="https://www.youtube.com/@QuranEducation-de9bg" >
                        <FontAwesomeIcon style={{ height: "40px", margin: "20px", color: "red" }} icon={faYoutube}></FontAwesomeIcon>
                    </a>
                    <a className='whtasap-btn' href="tel:+92 3331115789">
                        <FontAwesomeIcon style={{ height: "40px", margin: "20px", color: "" }} icon={faWhatsapp}></FontAwesomeIcon>
                        <ReactWhatsapp number="+" massage="Aslam ailkum Dear well com to Qeducation"></ReactWhatsapp>
                    </a>
                    <a href="https://instagram.com/qeducationonline?igshid=MzNlNGNkZWQ4Mg==">
                        <FontAwesomeIcon style={{ height: "40px", margin: "20px", color: "" }} icon={faInstagram}></FontAwesomeIcon>
                    </a>
                </div>
            </div>
            <div className="profile">
                <div className="Gmail" style={{ display: "flex" }}>
                    <img src={gmail} alt="" />
                    <span>Info@qeducationonline.com</span>
                </div>
                <div style={{ color: "black" }} className="text2">
                    <img src={whaticon} alt="" />
                    <ReactWhatsapp number="+92 3331115789" className='btn btn-primary'> +92 333 111 5789 </ReactWhatsapp>
                </div>
            </div>
        </div>
        </>
    )
}
