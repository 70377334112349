import React from 'react'
import './min1.css'
import Slider from "react-slick";
import ChatGpt from '../ChatGpt';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import null1 from '../../../img/null (1)-1.jpg'
import null2 from '../../../img/null (1)-2.jpg'
import null3 from '../../../img/null (1)-3.jpg'
import null4 from '../../../img/null (1)-4.jpg'
import null5 from '../../../img/null (1)-5.jpg'
import null6 from '../../../img/null (1)-6.jpg'
import null7 from '../../../img/null (1)-7.jpg'
import Eng1 from '../../../img/English Main Page (1)_00001.jpg'
import Eng2 from '../../../img/English Main Page (1)_00002.jpg'
import Eng3 from '../../../img/English Main Page (1)_00003.jpg'
import Eng4 from '../../../img/English Main Page (1)_00004.jpg'
import Eng5 from '../../../img/English Main Page (1)_00005.jpg'
import Eng6 from '../../../img/English Main Page (1)_00006.jpg'
import Eng7 from '../../../img/English Main Page (1)_00007.jpg'
export const Min1box = () => {
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     speed: 4000,
    //     autoplaySpeed: 5000,
    //     cssEase: "linear"
    // };
    return (
        <div className="continerbanerslider">
            <div class="d-container">
                <div class="p-2 ">
                    <img style={{width:"310px", height: "420px" }}     src={Eng1} alt=''/>
                    <img style={{marginTop:"10px"}} src={null1} alt="" />
                </div>
                <div class="p-2 ">
                    <img style={{width:"310px", height: "420px" }} src={Eng2} alt="" />
                    <img  style={{marginTop:"10px"}} src={null2} alt="" />
                </div>
                
            </div>
            <div class="d-container">
                <div class="p-2 ">
                    <img style={{width:"310px", height: "420px" }}     src={Eng3} alt=''/>
                    <img style={{marginTop:"10px"}} src={null3} alt="" />
                </div>
                <div class="p-2 ">
                    <img style={{width:"310px", height: "420px" }} src={Eng4} alt="" />
                    <img  style={{marginTop:"10px"}} src={null4} alt="" />
                </div>
                
            </div>

            <div class="d-container">
            <div class="p-2 ">
                  <img style={{width:"310px", height: "420px" }} src={Eng5} alt="" />
                    <img style={{marginTop:"10px"}} src={null5} alt="" />

                </div>
                <div class="p-2 ">
                <img style={{ height: "420px" }} src={Eng6} alt="" />
                    <img style={{marginTop:"10px"}} src={null6} alt="" />
                </div>
            </div>
            < ChatGpt />
            
        </div>
    )
}
