import React from 'react';
// import { Typewriter } from 'react-simple-typewriter';
import Videopass from '../Contant-img/Videopass';
import './contant.css'
import Backimg from "../../img/female-teacher-new-side-image.jpg"
import { Link } from 'react-router-dom';
import ChatGpt from '../ChatGPT/ChatGpt';
import { Helmet } from 'react-helmet-async';

function Contant() {
    // const { text } = useTypewriter({
    //     words: ['devloper'],
    //     loop: {},
    //     typeSpeed: 120,
    //     deleteSpeed: 80,
    // });
    return (
        <>
        <Helmet>
        <title>Q Education Online </title>
         <meta name="description" content=" Quran Academy offers online Quran classes and Islamic education for students of all ages. Learn Quran online with qualified teachers. " data-rh="ture" />
         <meta name="keywords" content="Quran Academy, Learn Quran Online, Islamic Education, Online Quran Classes, Quran Learning, Quran Teachers"/>
         <meta name="robots" content="index, follow"/>
         <link rel="Canonical" href="/Home" /> 
        </Helmet>
        <div className="c-contanier">
            <div className="min-circal-box">
                <span>FOR ALL AGES</span><br />
                <p>(Male & Female)</p>
            </div>

            {/* that contact display img and text only this code  */}
            <div className="contanier-text-img">
                <div className='Name-Aadmey'>

                    <h1 className='c-Aicontant'>Online Quranic
                        Education &
                        Islamic Courses
                    </h1>
                </div>
                <div className="background-img">
                    <div className="img-only">
                        <img src={Backimg} alt="" />
                    </div>

                </div>
            </div>
            <Link className="Contact" to="/Contact">
                <button className="c-start">Register Yourself</button>
            </Link>
            <Videopass />
            < ChatGpt />
        </div>
        </>
    );
}

export default Contant