import React from 'react'
import './ChatGpt.css'
import { NAvGpt } from './NavGPT/NAvGpt'



const ChatGpt = () => {
    return (
        <div className="Gptmain-contanier">
            <h1> Online Quranic Eductaion Courses</h1>
            <div className="container-courses">
                <div className="course-box1">
                    <span>Courses For Begginer</span>
                    <ul>
                        <li> Noorani Qaida</li>
                        <li>Tajweed- ul -Quran- il - Kareem</li>
                        <li> Masnoon Namaz</li>
                        <li>Masnoon Dua(Selective)</li>
                        <li> Short Ahadees-e-Nabvi (P.B.U.H)</li>
                    </ul>
                </div>
                <div className="course-box1">
                    <span>Courses For Intermediate</span>
                    <ul>
                        <li>Nazrah-t u l-Quran- il -Kareem</li>
                        <li>Tajweed- ul -Quran- il - Kareem (Selective)</li>
                        <li> Masnoon Namaz with Meanings</li>
                        <li>Ahadees-e-Nabvi (P.B.U.H) (Selective)</li>
                    </ul>
                </div>
                <div className="course-box1">
                    <span> Courses For High Level</span>
                    <ul>
                        <li>Tafseer-ul-Quran Shortly</li>
                        <li>Stories of Anbia ( A. S) & Sahaba ( R.A )</li>
                        <li>Basic Arabic Language Course</li>
                        <li>Complete Quran Tajweed Course</li>
                        <li> Masnoon Namaz with Meanings</li>
                        <li>Ahadees-e-Nabvi (P.B.U.H) (Selective)</li>
                        <li>Rules & problems (short)</li>
                    </ul>
                </div>
            </div>
            <div className="course-box2">
                <span>Quran Memorization </span>
                <ul>
                    <li>The previous Lessons(Sabqi & Manzil) </li>
                    <li>New Lesson of Quran(Everyday)</li>
                    <li>Complete Quran Tajweed Courses</li>
                    <li>Masnoon Dua</li>
                    <li>Stories of Anbia(A.s)& Sahaba (R.A) </li>
                    <li>Rules & problems (short)</li>
                </ul>
                <span style={{ fontSize: "24px" }}>Quran With Tarjuma</span>
                <ul>
                    <li>The Meanings of verses Letter by letter</li>
                    <li>Tafseer-ul-Quran Shortly </li>
                    <li>Basic Arabic Language Courses </li>
                </ul>
            </div>

        </div>
    )
}

export default ChatGpt