import React from 'react'
import "./resgiter.css"
import { Link,Router,Route,BrowserRouter } from 'react-router-dom'
import Form1 from '../formpage/Form1'

const Register = () => {
  return (
    <div>
  
    <div className="from-u-e-m">
                <div className='english-information'>
                <h1>Instructions for admission</h1>
                <ul>
                    <li>Enter your correct and complete information in the entry form.</li>
                    <li>After submitting the form all the process will be done through email for which write your valid email and active whatsapp number in the form. </li>
                    <li>After submitting the form, you will be informed about the interview date and time through email</li>
                    <li> will be done Zoom interview online via.</li>
                    <li>Important Note Mother or female guardian must be present at the time of interview.</li>
                    <li>After the admission is confirmed, the confirmation letter and fee voucher will be sent to you via email.</li>
                    <li>After depositing the admission fee you will be added to the WhatsApp group of the course.</li>
                    <li>The course rules and regulations and time table will be shared by the course incharge in the WhatsApp group. </li>
                </ul>
                </div>
             <div className='urdu-Admison-detial'>
                 <h1>ہدایات برائے داخلہ</h1>
                <ul>
                    <li>داخلہ فارم میں اپنی درست اور مکمل معلومات درج کریں۔  ▪️  </li>
                    <li>فارم جمع ہونے کے بعد تمام کاروائی بذریعہ ای میل کی جائے گی جس کے لیے فارم میں اپنا درست ای میل اورایکٹیو واٹس ایپ نمبر تحریر کریں ▪️ </li>
                    <li>فارم جمع ہونے کے بعد انٹرویو کی تاریخ اور وقت کی اطلاع آپ کو بذریعہ ای میل دی جائے گی۔  ▪️  </li>
                    <li> کیا جائے گا۔ Zoom انٹرویو آن لائن بذریعہ   ▪️  </li>
                    <li>اہم نوٹ انٹرویو کے وقت والدہ یا خاتون سرپرست کا ساتھ ہونا ضروری ہے</li>
                    <li>داخلہ کنفرم ہونے کے بعد کنفرمیشن لیٹر اور فیس واؤچر آپ کو بذریعہ ای میل بھیجے جائیں گے۔  ▪️  </li>
                    <li>داخلہ فیس جمع ہونے کے بعد آپ کو کورس کے واٹس ایپ گروپ میں شامل کر لیا جائے گا۔  ▪️  </li>
                    <li>کورس کے اصول و ضوابط اور ٹائم ٹیبل کورس انچارج کی طرف سے واٹس ایپ گروپ میں شئیر کیے جائیں گے۔  ▪️  </li>
                    <li>بيرون ملک سے داخلے کی خواہش مند امید وار بذریعہ ای میل رابطہ کریں  ▪️  </li>
                    <li>کورس کے قواعد و ضوابط اور ٹائم ٹیبل کو کورس انچارج واٹس ایپ گروپ میں شیئر کریں گے۔  ▪️ </li>
                </ul>
             </div>
                  
            </div>
            <div className="btn-Register-form">
            <Link className="Form1" to="/Form1">         
               <button className='Register-form'>Register Your Self  </button>                
            </Link> 
            </div>
    </div>
  )
}

export default Register
